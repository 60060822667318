


























































































































































import defaultComponent from "../../utils/defaultComponent";
import DataContainer from "../../common/DataContainer";
import { debounce } from "debounce";
import merge from "../../utils/merge";

export default defaultComponent.apply({
  data() {
    return {
      merge,
      originFieldData: {},
      fieldData: {},
      dirtyValue: false,
      dirtyFields: {},
      open: -1,
      index: 1,
      debouncedTriggerUpdate: debounce(() => {
        this.triggerUpdate();
      }, 500),
      loaded: false,
    };
  },
  watch: {
    open(value) {
      const self = this;
      if (value >= 0) {
        this.loaded = true;
      }
      setTimeout(() => {
        self.$emit("heightChanged");
      }, 1000);
    },
  },
  computed: {
    fieldRules() {
      const arr = [];
      for (const fieldName in this.options.filters) {
        const field = this.options.filters[fieldName];
        if (!field) {
          continue;
        }
        if (field.ext && field.ext.blank) {
          for (let i = 0; i < field.ext.blank; i++) {
            arr.push({
              field,
              fieldName,
              rule: {
                ext: {
                  blank: true,
                },
              },
              ruleName: `${i}`,
            });
          }
        } else {
          for (const ruleName in field.rules) {
            const rule = field.rules[ruleName];
            arr.push({
              field,
              fieldName,
              rule,
              ruleName,
            });
          }
        }
      }
      return arr;
    },
    importantFieldRules() {
      return this.fieldRules.filter((rule) => rule.field?.ext?.important);
    },
    unimportantFieldRules() {
      return this.fieldRules.filter((rule) => !rule.field?.ext?.important);
    },
  },
  methods: {
    initialize() {
      let dirty = false
      if (this.options.filters) {
        const filterValues = {};
        for (let filter of this.filters.value) {
          if (filter.origin) {
            filter = filter.origin;
          }
          if (filter.value === undefined) {
            continue;
          }
          if (!filter.operator) {
            filter.operator = "match";
          }
          filterValues[`${filter.key}-${filter.operator}`] = filter.value;
        }

        this.fieldData = {};
        for (const fieldName in this.options.filters) {
          const field = this.options.filters[fieldName];
          if (!field) {
            continue;
          }
          this.fieldData[fieldName] = {};
          for (const ruleName in field.rules) {
            const defaultValue = field.rules[ruleName]?.ext?.defaultValue || field.ext?.defaultValue
            this.fieldData[fieldName][ruleName] = new DataContainer(
              filterValues[`${fieldName}-${ruleName}`] || defaultValue
            );
            if (defaultValue) {
              dirty = true
            }
          }
        }
      }

      this.dirtyValue = this.dirty();
      // if (this.dirtyValue) {
      //   this.open = 0;
      // }

      this.$nextTick(() => {
        this.dirtyFields = {};
      });

      if (dirty) {
        this.triggerUpdate(true)
      }
    },
    dirty() {
      for (const fieldName in this.fieldData) {
        const fieldItem = this.fieldData[fieldName];
        for (const ruleName in fieldItem) {
          const ruleValue = fieldItem[ruleName].value;
          if (ruleValue !== undefined && ruleValue !== "") {
            return true;
          }
        }
      }
      return false;
    },
    onSearch() {
      this.debouncedTriggerUpdate();
    },
    clear() {
      for (const fieldName in this.fieldData) {
        const field = this.options.filters[fieldName];
        if (!field) {
          continue;
        }
        for (const ruleName in field.rules) {
          this.fieldData[fieldName][ruleName].value = undefined;
        }
      }
      this.index++;
      this.triggerUpdate();
    },
    triggerUpdate(preventEmit = false) {
      const filters = [];
      for (const fieldName in this.fieldData) {
        const field = this.options.filters[fieldName];
        if (!field) {
          continue;
        }
        const fieldItem = this.fieldData[fieldName];
        for (const ruleName in fieldItem) {
          const rule = field.rules[ruleName];
          const dataType = field.data_type;
          let ruleValue = fieldItem[ruleName].value;
          if (
            ruleValue === undefined ||
            (ruleValue === "" && !field.allowEmpty) ||
            (ruleValue instanceof Array && !ruleValue?.length && !field.allowEmpty)
          ) {
            continue;
          }
          if (ruleValue !== undefined) {
            if (!ruleValue && !this.dirtyFields[`${fieldName}-${ruleName}`]) {
              continue;
            }
            const operator = ruleName;
            if (dataType === "string") {
              ruleValue = ruleValue.toString();
            }
            if (rule.valueFilter) {
              ruleValue = rule.valueFilter(ruleValue);
            }
            const originFilter = {
              key: fieldName,
              operator,
              value: ruleValue,
            };
            if (rule.filterMaker) {
              const filter = rule.filterMaker(ruleValue, this.fieldData);
              if (filter) {
                if (filter instanceof Array) {
                  for (const f of filter) {
                    f.origin = originFilter;
                    filters.push(f);
                  }
                } else {
                  filter.origin = originFilter;
                  filters.push(filter);
                }
              }
              continue;
            }
            filters.push(originFilter);
          }
        }
      }
      this.filters.value = filters;
      if (preventEmit === true) {
        return;
      }
      this.$emit("search");
    },
  },
  extraOptions: {
    forwardStates: {
      xFilters: ["filters", () => []],
    },
  },
});
