





























































































































































































































































































































import defaultComponent from "../utils/defaultComponent";
import merge from "../utils/merge";
import humanizeString from "humanize-string";
import { titleCase } from "title-case";
import DataContainer from "../common/DataContainer";
import _ from "lodash";
import Search from "./x-data-table/Search.vue";
import Filters from "./x-data-table/Filters.vue";
import { encode, decode } from "../../../util/base64Util";

export default defaultComponent.apply({
  components: {
    Search,
    Filters,
  },
  data() {
    return {
      headers: [],
      dataOptions: {},
      customPerPage: 0,
      search: {
        search: new DataContainer(""),
        filters: new DataContainer([]),
        extraParams: new DataContainer({}),
      },
      searchContainer: new DataContainer(),
      filtersContainer: new DataContainer(),
      itemFieldSlots: {},
      dataOptionsChangedCount: 0,
      dataOptionsFirstChangeIgnored: false,
      itemKey: "",
      pageCount: new DataContainer(0),
      currentTableOptionsHash: "",
      ignoreUrlRestore: false,
      sortByAlt: {},
      displaySetting: {},
      height: "",
      currentItemsPerPage: 0,
      currentPage: 0,
      refreshId: 0,
    };
  },
  computed: {
    sortedTopActionButtons() {
      const arr = _.chain(this.options.content.topActionButtons)
        .filter((v) => v)
        .map((v, k) => {
          return { k, v };
        })
        .sortBy((item) => (item.v.ext && item.v.ext.sortPriority) || 0)
        .value();
      return arr;
    },
  },
  watch: {
    dataOptions: {
      handler() {
        if (
          this.dataOptionsChangedCount !== 0 ||
          !this.dataOptionsFirstChangeIgnored
        ) {
          this.onQueryChanged();
        }
        this.dataOptionsChangedCount++;
      },
      deep: true,
    },
    model: {
      handler(val) {
        if (val) {
          this.$emit("items-selected", val);
        }
      },
      deep: true,
    },
    $route() {
      if (this.options.content.urlEnabled) {
        if (this.ignoreUrlRestore) {
          this.ignoreUrlRestore = false;
          this.refresh();
          return;
        }
        this.restoreFromUrl();
      }
    },
  },
  methods: {
    initialize() {
      this.displaySetting = this.loadDisplaySetting();
      this.options.content.topActionButtons.showDisplaySetting = merge.clone(
        this.options.content.topActionButtons.showDisplaySetting,
        {
          target: {
            menu: {
              content: {
                cardContent: {
                  content: {
                    attrs: {
                      xOptions: {
                        content: {
                          displayFields: this.options.content.displayFields,
                          displaySetting: this.displaySetting,
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        }
      );

      this.itemKey = this.options.attrs["item-key"];
      if (!this._.isEmpty(this.options.content.multiSelectActionButtons)) {
        this.options.attrs["show-select"] = true;
      }
      for (const fieldName in this.options.content.displayFields) {
        const field = this.options.content.displayFields[fieldName];
        if (!field) {
          continue;
        }
        if (!field.options) {
          continue;
        }
        if (field.options.sortBy) {
          this.sortByAlt[fieldName] = field.options.sortBy;
          this.options.content.displayFields[fieldName].sortable = true;
        }
        this.itemFieldSlots["item." + fieldName] = field;
      }

      this.$nextTick(() => {
        this.updateDisplaySetting();
      });

      if (this.options.content.urlEnabled) {
        const restored = this.restoreFromUrl();
        if (restored) {
          this.dataOptionsFirstChangeIgnored = true;
          this.refresh();
        }
      }
    },
    getTableOptionsHash() {
      const tableOptions: any = {
        dataOptions: this.dataOptions,
        customPerPage: this.customPerPage,
      };

      if (this.search.search.value) {
        tableOptions.search = this.search.search.value;
      }
      if (this.search.filters.value && !_.isEmpty(this.search.filters.value)) {
        tableOptions.filters = this.search.filters.value;
      }
      if (
        this.search.extraParams.value &&
        !_.isEmpty(this.search.extraParams.value)
      ) {
        tableOptions.extraParams = this.search.extraParams.value;
      }
      return encode(JSON.stringify(tableOptions));
    },
    restoreFromUrl() {
      const query = this.$route.query;
      if (query.table_options) {
        const tableOptions = JSON.parse(decode(query.table_options));
        if (tableOptions.dataOptions) {
          for (const k in tableOptions.dataOptions) {
            this.dataOptions[k] = tableOptions.dataOptions[k];
          }
        }
        this.customPerPage = parseInt(tableOptions.customPerPage, 10);

        this.search.search.value = tableOptions.search || "";
        if (tableOptions.filters) {
          this.search.filters.value = tableOptions.filters;
        }
        if (tableOptions.extraParams) {
          this.search.extraParams.value = tableOptions.extraParams;
        }
        return true;
      }
      return false;
    },
    onQueryChanged() {
      if (this.options.content.urlEnabled) {
        this.currentTableOptionsHash = this.getTableOptionsHash();
        if (this.currentTableOptionsHash !== this.$route.query.table_options) {
          this.ignoreUrlRestore = true;
          const query = (this.$route.query && { ...this.$route.query }) || {};
          query.table_options = this.currentTableOptionsHash;
          this.$router
            .push({
              path: this.$route.path,
              query,
            })
            .catch(console.error);
          return;
        }
      }
      this.refresh();
    },
    updateDisplaySetting(value) {
      if (value) {
        this.displaySetting = value;
      }
      const newHeaders: any[] = [];
      for (const fieldName in this.options.content.displayFields) {
        let field = this.options.content.displayFields[fieldName];
        if (!field) {
          continue;
        }
        if (
          this.displaySetting.displayFields &&
          this.displaySetting.displayFields[fieldName] !== undefined
        ) {
          if (!this.displaySetting.displayFields[fieldName]) {
            continue;
          }
        } else if (field.hidden) {
          continue;
        }
        if (!field.text) {
          field.text = titleCase(humanizeString(fieldName));
        }
        if (field.makeAttrs) {
          field = field.makeAttrs(this, field);
        }
        let header: any = {
          value: fieldName,
          sortable: false,
        };
        for (const k in field) {
          if (k === "options") {
            continue;
          }
          header[k] = field[k];
        }
        header = merge.clone(header, field.attrs);
        newHeaders.push(header);
      }
      this.headers = newHeaders;
    },
    getFindAllOptions() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.dataOptions;
      let limit = parseInt(this.customPerPage, 10) || itemsPerPage;
      let offset = limit * (page - 1);
      if (limit <= 0) {
        limit = -1;
        offset = 0;
      }
      let findAllOptions: any = {
        limit,
        offset,
        searchBy: this.options.content.searchBy,
        search: this.search.search.value,
        orders: {},
        filters: [],
        extraParams: {},
      };

      for (const i in sortBy) {
        let fieldName = sortBy[i];
        if (this.sortByAlt[fieldName]) {
          fieldName = this.sortByAlt[fieldName];
        }
        const orderType = sortDesc[i] ? "desc" : "asc";
        findAllOptions.orders[fieldName] = orderType;
        findAllOptions.orderBy = fieldName;
        findAllOptions.orderType = orderType;
      }
      if (findAllOptions.limit === -1) {
        delete findAllOptions.limit;
        findAllOptions.offset = 0;
      }
      for (const filter of this.search.filters.value) {
        findAllOptions.filters.push(filter);
      }
      if (Object.keys(this.search.extraParams.value).length > 0) {
        findAllOptions.extraParams = {
          ...findAllOptions.extraParams,
          ...this.search.extraParams.value,
        };
      }
      if (this.options.content.findAllOptionsFilter) {
        findAllOptions = this.options.content.findAllOptionsFilter.call(
          this,
          findAllOptions
        );
      }
      findAllOptions.payload = JSON.stringify(findAllOptions);
      return findAllOptions;
    },
    async refresh(options: any = {}) {
      if (!options.disableLoading) {
        this.loading.value = true;
      }

      this.refreshId++;
      const currentRefreshId = this.refreshId;

      try {
        const [data, count] = await this.options.ext.dataProvider.findAll(
          this.getFindAllOptions(),
          this
        );
        if (currentRefreshId !== this.refreshId) {
          return;
        }
        this.items.value = [];
        for (const i in data) {
          this.items.value.push(data[i]);
        }
        this.itemCount.value = count;
        if (this.options.content.selectedItemsMaker) {
          this.model.value = this.options.content.selectedItemsMaker(
            this,
            this.items.value
          );
        } else if (options.clearSelectedItems) {
          this.model.value = [];
        }
        if (this.options.content.expandByDefault) {
          this.expanded.value = [...this.items.value];
        }

        {
          this.currentPage = this.dataOptions.page;
          this.currentItemsPerPage =
            parseInt(this.customPerPage, 10) || this.dataOptions.itemsPerPage;
        }

        this.recalculateHeight(true);
      } catch (err) {
        if (currentRefreshId === this.refreshId) {
          this.error.value = err;
        }
      } finally {
        if (currentRefreshId === this.refreshId) {
          this.loading.value = false;
        }
      }
    },
    async execute(operationId: any, params: any, options: any = {}) {
      if (!options.disableLoading) {
        this.loading.value = true;
      }
      try {
        let result;
        if (typeof operationId === "function") {
          result = await operationId();
        } else if (operationId.indexOf(".") === -1) {
          result = await this.options.ext.dataProvider.execute(
            operationId,
            params,
            this
          );
        } else {
          const operation = operationId.split(".")[1];
          result = await this.options.ext.dataProvider[operation](
            ...params,
            this
          );
        }
        if (result) {
          if (options.willRefresh) {
            await this.refresh({
              clearSelectedItems: true,
            });
          }
          if (options.successMessage) {
            if (typeof options.successMessage === "function") {
              options.successMessage = options.successMessage(result);
            }
            this.info.value = options.successMessage;
          }
        }
        return result;
      } catch (err) {
        this.error.value = err;
      } finally {
        this.loading.value = false;
      }
    },
    displaySettingKey() {
      let id = this.options.content.id;
      if (!id) {
        const matched = this.$route.matched;
        id =
          (matched &&
            matched.length &&
            matched[matched.length - 1] &&
            (matched[matched.length - 1] as any).path) ||
          this.$route.path;
      }
      return `vuetifyx.xDataTable-${id}`;
    },
    loadDisplaySetting() {
      const valueData = localStorage.getItem(this.displaySettingKey());
      if (!valueData) {
        return {};
      }
      return JSON.parse(valueData);
    },
    saveDisplaySetting(value) {
      if (value === null) {
        this.updateDisplaySetting({});
        localStorage.removeItem(this.displaySettingKey());
      } else {
        if (value) {
          this.updateDisplaySetting(value);
        }
        localStorage.setItem(
          this.displaySettingKey(),
          JSON.stringify(this.displaySetting)
        );
      }
    },
    displaySettingDirty() {
      return !_.isEmpty(this.displaySetting);
    },
    recalculateHeight(scrollToTop) {
      if (
        !this.options ||
        !this.options.attrs ||
        !this.options.attrs["fixed-header"]
      ) {
        return;
      }

      if (!this.$refs.dataTable) {
        return;
      }
      this.$nextTick(() => {
        const el = this.$refs.dataTable.$el;
        const header = el.querySelector("header");
        const footer = el.querySelector(".v-data-footer");
        const headerHeight = header ? header.getBoundingClientRect().height : 0;
        const footerHeight = footer
          ? footer.getBoundingClientRect().height
          : 12;
        const dataTableWrapper = el.querySelector(".v-data-table__wrapper");
        const contentHeight = dataTableWrapper.scrollHeight;
        let newHeight =
          window.innerHeight -
          el.getBoundingClientRect().top -
          headerHeight -
          footerHeight;
        if (newHeight >= contentHeight) {
          newHeight = undefined;
        } else if (newHeight < 200) {
          newHeight = undefined;
        }
        this.height = newHeight;
        if (scrollToTop) {
          dataTableWrapper.scrollTop = 0;
        }
      });
    },
  },
  mounted() {
    this.recalculateHeight();
    window.addEventListener("resize", () => {
      this.recalculateHeight();
    });
  },
  extraOptions: {
    defaultClass: "dataTable",
    forwardStates: {
      xExpanded: ["expanded", () => []],
      xItems: ["items", () => []],
      xItemCount: ["itemCount", -1],
      xModel: ["model", () => []],
      xError: ["error"],
      xInfo: ["info"],
      xData: ["data"],
      xLoading: ["loading", false],
    },
  },
});
